.link-item::after{
  content:"";
  position: absolute;
  top: 16%;
  right: 0px;
  height: 70%;
  width: 5px;
  border-radius: 5px;
  background: #F7D372;
  z-index: 1;
  transition: all .9s ease-in-out;
}
.progress-bar-folder > div {
  background: #7FCACF;
}

.tab-item-qcm::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* .tab-item-qcm::before{
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100px;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(98,98,169,0) 100%);
  z-index: 1;
  pointer-events: none
}
.tab-item-qcm::after{
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  background: linear-gradient(90deg, rgba(98,98,169,0) 0%, rgba(255,255,255,1) 100%);
  z-index: 1;
  pointer-events: none
} */


/* tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2);
  padding: 0;
} */

/* tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
} */

.content{
  display: none;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content.show{
  display: table-row;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.updates-series-grid::-webkit-scrollbar, .tab-item-qcm::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.updates-series-grid, .tab-item-qcm {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (max-width: 520px) {
  .subject-name{
    max-width: 150px;
  }
}
@media (max-width: 380px) {
  .subject-name{
    max-width: 80px;
  }
}
.swiper-container-example:hover .my-arrow{
  display: block !important;
}

.my-arrow{
  display: none !important;
}

@media (max-width: 768px) {
  .my-arrow{
    display: block !important;
  }
}